<template>
  <v-container>
    <h1 v-html="$t('step_four_title')"></h1>
    <v-row>
      <v-col xl="6">
        <h2 v-html="$t('step_four_title_gesuchsteller')"></h2>
        <v-row>
          <v-col xl="3">
            <v-text-field id="block" disabled :label="$t('step_four_mbh_id')" v-model="processingForm.dairyIdent" type="number" required></v-text-field>
          </v-col>
          <v-col xl="3">
            <v-text-field
              id="block"
              disabled
              :label="$t('step_four_mbh_dairy_type_name')"
              v-model="processingForm.dairyTypeNameDe"
              type="text"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-col xl="6">
          <v-textarea
            readonly
            filled
            rows="5"
            id="step_four_formatted_address"
            :label="$t('step_four_formatted_address')"
            v-model="processingForm.dairyFormattedAddress"
            required
          ></v-textarea>
        </v-col>
      </v-col>
      <v-col xl="6">
        <h2 v-html="$t('step_four_title_meldepflicht')"></h2>
        <v-col xl="6">
          <v-text-field
            id="block"
            disabled
            :label="$t('step_four_processing_reporting_obligation')"
            v-model="processingForm.dairyProcessingReportingObligation"
            required
          ></v-text-field>
        </v-col>

        <v-col xl="6">
          <v-text-field
            id="block"
            disabled
            :label="$t('step_four_production_reporting_obligation')"
            v-model="processingForm.dairyProductionReportingObligation"
            required
          ></v-text-field>
        </v-col>

        <v-col xl="6">
          <v-text-field
            id="block"
            disabled
            :label="$t('step_four_segmentation_reporting_obligation')"
            v-model="processingForm.dairySegmentationReportingObligation"
            required
          ></v-text-field>
        </v-col>

        <v-col xl="6">
          <v-text-field id="block" disabled :label="$t('step_four_period')" v-model="processingForm.formPeriodNameDe" required></v-text-field>
        </v-col>
      </v-col>
    </v-row>
    <h2 v-html="$t('step_four_available_documents')"></h2>

    <v-row>
      <v-col xl="1">
        <h2 id="step_four_print" v-html="$t('step_four_print')"></h2>
      </v-col>
      <v-col xl="3">
        <h2 id="step_four_document" v-html="$t('step_four_document')"></h2>
      </v-col>
      <v-col xl="3">
        <h2 id="step_four_period" v-html="$t('step_four_period')"></h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col xl="1">
        <v-switch :disabled="!paperReport.subsidyInvoiceId" id="print_step_four_subsidy_invoice" v-model="printSubsidyInvoice" color="primary"></v-switch>
      </v-col>
      <v-col xl="3">
        <v-text-field id="text_step_four_subsidy_invoice" disabled :value="$t('step_four_subsidy_invoice')" required></v-text-field>
      </v-col>
      <v-col xl="3">
        <v-text-field id="period_step_four_subsidy_invoice" disabled :value="subsidyInvoicePeriod" required></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col xl="1">
        <v-switch :disabled="!paperReport.processingFormId" id="print_step_four_processing_form" v-model="printProcessingForm" color="primary"></v-switch>
      </v-col>
      <v-col xl="3">
        <v-text-field id="text_step_four_processing_form" disabled :value="$t('step_four_processing_form')" required></v-text-field>
      </v-col>
      <v-col xl="3">
        <v-text-field id="period_step_four_processing_form" disabled :value="processingFormPeriod" required></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col xl="1">
        <v-switch :disabled="!paperReport.productionFormId" id="print_step_four_production_form" v-model="printProductionForm" color="primary"></v-switch>
      </v-col>
      <v-col xl="3">
        <v-text-field id="text_step_four_production_form" disabled :value="$t('step_four_production_form')" required></v-text-field>
      </v-col>
      <v-col xl="3">
        <v-text-field id="period_step_four_production_form" disabled :value="productionFormPeriod" required></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col xl="1">
        <v-switch :disabled="!paperReport.segmentationFormId" id="print_step_four_segmentation_form" v-model="printSegmentationForm" color="primary"></v-switch>
      </v-col>
      <v-col xl="3">
        <v-text-field id="text_step_four_segmentation_form" disabled :value="$t('step_four_segmentation_form')" required></v-text-field>
      </v-col>
      <v-col xl="3">
        <v-text-field id="period_step_four_segmentation_form" disabled :value="segmentationFormPeriod" required></v-text-field>
      </v-col>
    </v-row>

    <v-btn class="m-2" id="back" @click="goBack" color="secondary"><span v-html="$t('processing_form_edit_step_four_back')" /></v-btn>
    <v-btn id="cancel" @click="cancel" color="secondary"><span v-html="$t('processing_form_edit_step_three_cancel')" /></v-btn>
    <v-btn
      :loading="loadingDocument"
      :disabled="!printProcessingForm && !printProductionForm && !printSegmentationForm && !printSubsidyInvoice && !loadingDocument"
      id="generatePDFs"
      class="m-2"
      @click="generateAndGetPDFs(true)"
      color="primary"
      ><span v-html="$t('step_four_print')"
    /></v-btn>
    <v-btn
      v-if="$getBeta()"
      :loading="loadingResponse"
      :disabled="!printProcessingForm && !printProductionForm && !printSegmentationForm && !printSubsidyInvoice && !loadingDocument"
      id="sendPDFs"
      class="ml-0 beta"
      @click="generateAndGetPDFs(false)"
      color="primary"
      ><span v-html="$t('step_four_post')"
    /></v-btn>

    <router-link
      v-if="sentDocumentsExist && $getBeta()"
      class="m-2 link beta"
      :to="{
        name: 'messages_sent_read',
        query: {
          tags: 'ProcessingFormId:' + $route.params.id,
          sendingChannel: 'POST',
          page: 0,
          size: 50
        }
      }"
    >
      <span v-html="$t('step_four_post_link')"></span
    ></router-link>
  </v-container>
</template>

<script lang="ts">
import { apiURL, emitter } from '../../../main'
import { showError } from '@/services/axios'
import { fileCreator } from '@/services/axios'

import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      sentDocumentsExist: false,
      loadingDocument: false,
      loadingResponse: false,
      processingForm: { dairyIdent: null },
      printProcessingForm: false,
      printProductionForm: false,
      printSegmentationForm: false,
      printSubsidyInvoice: false,
      paperReport: {
        processingFormId: this.$route.params.id,
        processingFormValidFrom: '',
        processingFormValidUntil: '',
        productionFormId: undefined,
        productionFormValidFrom: '',
        productionFormValidUntil: '',
        subsidyInvoiceId: undefined,
        subsidyInvoiceValidFrom: '',
        subsidyInvoiceValidUntil: '',
        segmentationFormValidFrom: '',
        segmentationFormValidUntil: '',
        segmentationFormId: undefined
      }
    }
  },
  methods: {
    async getProcessingForm() {
      try {
        const result = await this.axios.get(
          apiURL + '/processingForms/' + this.$route.params.id,
          {
            id: this.$route.params.id
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async getPaperReport() {
      try {
        const result = await this.axios.get(apiURL + '/processingForms/' + this.$route.params.id + '/paperreports', {
          headers: { 'Content-Type': 'application/json' }
        })
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async checkSentDocumentsExist() {
      try {
        const response = await this.axios.get(apiURL + '/messages/search', {
          headers: { 'Content-Type': 'application/json' },
          params: {
            sendingChannel: 'POST',
            tags: 'form:' + this.$route.params.id,
            size: 50
          }
        })
        const items = await response.data.content.totalElements
        this.items = Array.isArray(items) ? items : []
        if (this.checkPage(response.data.totalElements) != 0) {
          this.sentDocumentsExist = true
          return response.data
        }
        this.sentDocumentsExist = false
      } catch (e) {
        this.sentDocumentsExist = false
      }
    },
    async generateAndGetPDFs(download: boolean) {
      const payload = {
        processingFormId: this.paperReport.processingFormId && this.printProcessingForm ? this.paperReport.processingFormId : 0,
        productionFormId: this.paperReport.productionFormId && this.printProductionForm ? this.paperReport.productionFormId : 0,
        segmentationFormId: this.paperReport.segmentationFormId && this.printSegmentationForm ? this.paperReport.segmentationFormId : 0,
        subsidyInvoiceId: this.paperReport.subsidyInvoiceId && this.printSubsidyInvoice ? this.paperReport.subsidyInvoiceId : 0
      }
      if (download) {
        try {
          this.loadingDocument = true
          const result = await this.axios.get(apiURL + '/processingForms/' + this.$route.params.id + '/paperreports/pdf', {
            headers: { Accept: 'application/pdf' },
            responseType: 'blob',
            params: {
              ...payload
            }
          })
          if (await result) {
            fileCreator(result, 'PapierMeldung.pdf')
          }
        } catch (e) {
          //TODO: possibly find a better solution for handling these blob errors
          let responseObj = await e.response.data.text()
          showError({ response: { data: JSON.parse(responseObj) } })
        } finally {
          this.loadingDocument = false
        }
      } else {
        try {
          this.loadingResponse = true
          const result = this.axios.get(apiURL + '/processingForms/' + this.$route.params.id + '/paperreports/pdf/post', {
            headers: { Accept: 'application/pdf' },
            params: {
              ...payload
            }
          })
          if (await result) {
            emitter.emit('toastSuccess', this.$t('step_four_success'))
            this.loadingResponse = false
            await this.checkSentDocumentsExist()
          }
        } catch (e) {
          showError(e)
          this.loadingResponse = false
        }
      }
    },
    goBack() {
      this.$router
        .push({
          name: 'processingForms_edit_form_step_three',
          params: { id: this.$route.params.id }
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    cancel() {
      this.$router
        .push({
          name: 'processingForms_read'
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    setSwitchDefault() {
      if (this.paperReport.subsidyInvoiceId && this.processingForm.dairyProcessingReportingObligation == 'YES_PAPER') {
        this.printSubsidyInvoice = true
      }
      if (this.paperReport.processingFormId && this.processingForm.dairyProcessingReportingObligation == 'YES_PAPER') {
        this.printProcessingForm = true
      }

      if (this.paperReport.productionFormId && this.processingForm.dairyProductionReportingObligation == 'YES_PAPER') {
        this.printProductionForm = true
      }

      if (this.paperReport.segmentationFormId && this.processingForm.dairySegmentationReportingObligation == 'YES_PAPER') {
        this.printSegmentationForm = true
      }
    }
  },
  computed: {
    processingFormPeriod() {
      if (!this.paperReport.processingFormId) {
        return this.$t('step_four_not_found')
      }
      if (this.paperReport.processingFormValidFrom && this.paperReport.processingFormValidUntil) {
        return `${this.paperReport.processingFormValidFrom} - ${this.paperReport.processingFormValidUntil}`
      }
      return ''
    },
    productionFormPeriod() {
      if (!this.paperReport.productionFormId) {
        return this.$t('step_four_not_found')
      }
      if (this.paperReport.productionFormValidFrom && this.paperReport.productionFormValidUntil) {
        return `${this.paperReport.productionFormValidFrom} - ${this.paperReport.productionFormValidUntil}`
      }
      return ''
    },
    segmentationFormPeriod() {
      if (!this.paperReport.segmentationFormId) {
        return this.$t('step_four_not_found')
      }
      if (this.paperReport.segmentationFormValidFrom && this.paperReport.segmentationFormValidUntil) {
        return `${this.paperReport.segmentationFormValidFrom} - ${this.paperReport.segmentationFormValidUntil}`
      }
      return ''
    },
    subsidyInvoicePeriod() {
      if (!this.paperReport.subsidyInvoiceId) {
        return this.$t('step_four_not_found')
      }
      if (this.paperReport.subsidyInvoiceValidFrom && this.paperReport.subsidyInvoiceValidUntil) {
        return `${this.paperReport.subsidyInvoiceValidFrom} - ${this.paperReport.subsidyInvoiceValidUntil}`
      }
      return ''
    }
  },
  mounted: function () {
    this.$nextTick(async () => {
      this.processingForm = await this.getProcessingForm()
      this.paperReport = await this.getPaperReport()
      await this.checkSentDocumentsExist()
      this.setSwitchDefault()
    })
  }
})
</script>

<style></style>
